
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, {LANGUAGE_KEY} from '@/services/StorageService'
import Multiselect from "vue-multiselect";
import OrganizationsUtil from "@/utils/OrganizationsUtil";
import ValidateUtil from "@/utils/ValidateUtil";
import * as moment from "moment";
import DateUtil from "@/utils/DateUtil";
import * as momentTimezone from "moment-timezone";
const TIMEZONE = 'Europe/Rome';

export default {

  components: {
    Multiselect
  },

  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage('Events', StorageService.getFromStorage(LANGUAGE_KEY))

    return {
      eventsSnippet: snippets ?? {},
      eventId: this.$route.params.id,
      name: '',
      description: '',
      editedItem: {
        userId: '',
        name: '',
        description: '',
        specialRequest: '',
        specialTimeRequest: '',
        photos: [],
        photoIds: [],
        isPublic: true,
        serviceIds: [],
        availableFavours: [],
        booking: {}
      },
      mobileNavBar: false,
      editedIndex: -1,
      model: +sessionStorage.getItem('model') ?? 0,
      facilitiesList: [],
      selectedFacility: {},
      selectedFacilitySpace: {},
      selectedDate: '',
      selectedTime: '',
      eventCapableFacilityList: [],
      facilitySpacesList: [],
      availableDates: [],
      menu2: false,
      date: '',
      nowDate: new Date().toISOString().slice(0, 10),
      times: [],
      carouselPhotos: [],
      toDeletePhotoIndex: -1,
      dialogCarousel: false,
      dialogDeletePhoto: false,
      dialogDelete: false,
      selectedServices: [],
      services: [],
      organizations: [],
      selectedOrganization: [],
      allUsers: [],
      users: [],
      participants: [],
      newParticipants: [],
      selectedUser: '',
      selectedParticipants: [],
      selectedNewParticipants: [],
      rules: {
        email: value => {
          if (!value) {
            return true;
          }

          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

          if (!pattern.test(value)) {
            return 'Invalid e-mail.';
          }

          if (this.selectedNewParticipants.includes(value)) {
            return 'User with email has already added';
          }

          return true;
        },
      },
      newParticipantEmail: '',
      submitted: false,
      globalError: true,
      errorKey: '',
      errors: {
        nameError: '',
        descriptionError: '',
        dateError: '',
        timeError: '',
        organizationError: '',
        userError: '',
        participantsError: ''
      }
    }
  },

  computed: {

    defaultSpaceImage() {
      return require('../../assets/images/facilities/no-image.svg');
    },

  },

  watch: {

    model(val) {
      sessionStorage.setItem('model', val);
    },

    dialogCarousel(val) {
      val || this.closeDialogCarousel()
    },

    dialogDelete(val) {
      val || this.closeDelete()
    },

    dialogDeletePhoto(val) {
      val || this.closeDelete()
    },

    dialogUpgrade(val) {
      val || this.closeUpgrade()
    },

  },

  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem('model');
    StorageService.deleteEventToStorage();
    next()
  },

  async mounted() {
    this.validateUtil = new ValidateUtil(this.errors);
    await this.getEventDetailsFromApi();
    await this.getFacilitiesFromApi();
    await this.getOrganizationsFromApi();

    this.changeDate(this.date)

    this.loading = false;
  },

  methods: {
    getTimeName(time) {
      const startsInMorning = time?.match(/^(07|08|09|10|11)/gm)
      const endsInEvening = time?.match(/-(17|18|19|20|23):.+$/gm)

      if (startsInMorning && !endsInEvening) {
        return 'Morning'
      }

      if (!startsInMorning && endsInEvening) {
        return 'Afternoon'
      }

      return time ? 'All day' : ''
    },

    allowedDates(val) {
      if (this.dates.length) {
        return this.dates.includes(val);
      }

      return new Date();
    },

    async getEventDetailsFromApi() {
      this.editedItem = await ApiRequestService.getRequest(`api/admin-facility-events/detail/${this.eventId}`);
      StorageService.setEventToStorage( this.editedItem );

      this.oldEvent = this.getObjClone(this.editedItem);
    },

    changePublic(val) {
      this.editedItem.isPublic = val;
    },

    async getFacilitiesFromApi() {
      let facilityData = await ApiRequestService.getRequest(`api/admin-facility-spaces/event-capable`);
      await this.setFacilitiesList(facilityData.length ? facilityData : []);
    },

    async setFacilitiesList(facilitiesData) {
      this.facilitiesList = [];

      for (let item of facilitiesData) {
        if (item.facility.id !== this.editedItem.booking.facilityId) {
          continue;
        }

        this.eventCapableFacilityList = item;
        this.facilitiesList.push({
          id: item.facility.id,
          name: item.facility.name,
        })
      }

      await this.initSpaceSelections();

    },

    async initSpaceSelections() {
      this.selectedFacility = {
        "id": this.editedItem.booking.facilityId,
        name: this.editedItem.booking.facilityName
      };

      this.selectedFacilitySpace = {
        "id": this.editedItem.booking.spaceId,
        name: this.editedItem.booking.spaceName
      };

      this.facilitySpacesList = [this.selectedFacilitySpace];

      this.services = this.editedItem.availableFavours;
      this.editedItem.serviceIds.forEach(selectedServiceItemId => {
        this.selectedServices = this.selectedServices.concat(this.services.filter(serviceItem => {
          return selectedServiceItemId === serviceItem.id
        }))
      });


      this.date = this.getFormattedDateString(this.editedItem.bookingDate);
      console.log(this.date);
      await this.getFacilitiesSpacesAvailableDatesApi();
    },

    async getFacilitiesSpacesAvailableDatesApi() {
      this.dates = [];
      this.availableDates = [];
      let spaceId = this.selectedFacilitySpace.id;
      let facilityId = this.selectedFacility.id;


      let today = this.editedItem.bookingDate ? new Date(this.editedItem.bookingDate) : new Date();
      let year = today.getFullYear()
      let month = today.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;

      let availableDatesData = await ApiRequestService.getRequest(`api/admin-facility-events/${facilityId}/space/${spaceId}/event/schedule/${year}-${month}`);
      this.setFacilityDatesList(availableDatesData);
    },

    getFormattedDateString(date) {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }

      let getYear = date.toLocaleDateString('default', {year: "numeric"});
      let getMonth = date.toLocaleDateString('default', {month: "2-digit"});
      let getDay = date.toLocaleDateString('default', {day: "2-digit"});

      return `${getYear}-${getMonth}-${getDay}`;
    },

    setFacilityDatesList(datesData) {

      let today = new Date();
      let alreadyScheduledData = {};
      let eventAllowedTimeRanges = datesData.eventAllowedTimeRanges;

      for(let [key,value] of Object.entries(eventAllowedTimeRanges)){
        value?.map(item => {
          item.startTime = DateUtil.getItalianTimeFromUTC(moment(new Date(key)).format( 'YYYY-MM-DD' ) + ' ' + item.startTime?.substr(0, 5));
          item.endTime = DateUtil.getItalianTimeFromUTC(moment(new Date(key)).format( 'YYYY-MM-DD' ) + ' ' + item.endTime?.substr(0, 5));
          return item;
        })
      }

      for (let date in datesData.schedule) {
        console.log('this.editedItem.booking.facilityId', this.editedItem.booking.facilityId)
        let filteredBusyDates = datesData.schedule[date].filter(item => {
          return item.facilityName && item.spaceName && item.facilityId !== this.editedItem.booking.facilityId
        });

        if (!filteredBusyDates.length) {
          continue;
        }

        alreadyScheduledData[date] = filteredBusyDates;
      }


      for (let date in eventAllowedTimeRanges) {
        let dateTime = new Date(date);

        if (dateTime.getMonth() === today.getMonth() && dateTime.getDate() < today.getDate()) {
          continue;
        }

        let currentDayTimesItem = eventAllowedTimeRanges[date];
        let formattedDate = this.getFormattedDateString(date);

        currentDayTimesItem.map(item => item.name = `${item.startTime}-${item.endTime}`);

        if (alreadyScheduledData.hasOwnProperty(date)) {
          let scheduledItem = alreadyScheduledData[date];
          let existingIndexes = new Set();

          scheduledItem.forEach(scheduledTimeItem => {
            for (let itemIndex in currentDayTimesItem) {
              let timeItem = currentDayTimesItem[itemIndex];

              if (!timeItem.startTime.match(scheduledTimeItem.startTime) && timeItem.endTime.match(scheduledTimeItem.endTime)) {
                continue;
              }

              existingIndexes.add(itemIndex);
            }
          });

          [...existingIndexes].forEach(item => {
            currentDayTimesItem = currentDayTimesItem.slice(item, 1)
          });

          if (this.editedItem.endTime && this.editedItem.startTime && this.editedItem.bookingDate === date) {
            currentDayTimesItem.push({name: `${this.editedItem.startTime.substring(0, 5)}-${this.editedItem.startTime.substring(0, 5)}`})
          }
        }

        if (!currentDayTimesItem.length) {
          continue;
        }

        currentDayTimesItem = currentDayTimesItem.flatMap(item => item.name);
        this.dates.push(formattedDate);

        this.availableDates.push({
          date: formattedDate,
          time: currentDayTimesItem,
        });
        console.log('this.date', this.date)
        if (this.date) {
          this.changeDate(this.date)
        }
      }
    },

    selectService(val) {
      this.selectedServices = val;
    },

    removeService(val) {
      this.selectedServices = this.selectedServices.filter(item => {
        return item.id !== val.id
      });
    },

    selectTime(val) {
      this.selectedTime = val;
    },

    async getOrganizationsFromApi() {
      this.organizationsUtil = new OrganizationsUtil('api/admin-organizations', this.organizations, this.selectOrganization);
      await this.organizationsUtil.getOrganizations();
      await this.initOrganizationAndParticipantsSelection();
    },

    async initOrganizationAndParticipantsSelection() {
      let selectedOrganization = this.organizations.filter(item => {
        return item.id === this.editedItem.organizationId;
      });

      if (!selectedOrganization) {
        return;
      }

      await this.selectOrganization(selectedOrganization[0]);
      let selectedUser = this.users.filter(item => {
        return item.userId === this.editedItem.userId
      });
      await this.selectUser(selectedUser[0]);

      if (!this.editedItem.booking.participants || !this.editedItem.booking.participants.length) {
        return;
      }

      let participants = [];
      let newParticipants = [];

      const existingParticipant = (email) => this.participants.find((p) => p.email === email);

      this.editedItem.booking.participants.forEach(item => {
        let existingUser = existingParticipant(item.email);
        if (existingUser) {
          participants.push(existingUser);
        } else {
          newParticipants.push(item.email);
        }
      })

      this.selectedParticipants = participants;
      this.selectedNewParticipants = newParticipants;
      this.newParticipants = newParticipants;
    },

    removeOrganization() {
      this.selectedOrganization = {};
      this.selectedUser = {};
      this.participants = [];
      this.users = [];
      this.selectedParticipants = [];
    },

    removeUser() {
      this.selectedUser = {};
    },

    async selectOrganization(val) {
      this.selectedOrganization = val;
      this.selectedUser = '';
      this.participants = [];
      this.users = [];
      this.selectedParticipants = [];

      await this.organizationsUtil.getOrganizationUsers(this.selectedOrganization, this.setUsersList);
      this.allUsers = await this.organizationsUtil.getAllUsers();
    },

    setUsersList(usersData) {
      this.users = usersData;
      this.participants = usersData;
    },

    selectUser(val) {
      this.selectedUser = val;

      this.participants = this.allUsers.filter(item => {
        return item.userId !== val.userId
      });
    },

    addNewEmailParticipant(val) {
      if (this.rules.email(val) === 'Invalid e-mail.' || val === '') {
        return;
      }

      if (!this.selectedNewParticipants.includes(val)) {
        this.selectedNewParticipants.push(val);
      }

      if (!this.newParticipants.includes(val)) {
        this.newParticipants.push(val);
      }

      this.newParticipantEmail = ''
    },

    removeNewParticipants(val) {
      if (this.newParticipants.includes(val)) {
        this.newParticipants = this.newParticipants.filter(item => item !== val);
      }

      if (this.selectedNewParticipants.includes(val)) {
        this.selectedNewParticipants = this.selectedNewParticipants.filter(item => item !== val);
      }
    },

    selectParticipants(val) {
      this.users = this.users.filter(item => {
        return item.userId !== val.userId
      })
    },

    setPhotos(files) {
      if (files && files.length) {
        if (files.length + this.editedItem.photos.length > 10) {
          this.errorKey = 'Is not able to upload more then 10 images';
          this.$refs.fileupload.reset();
        } else {
          this.errorKey = '';
          this.editedItem.photoIds = files;
        }
      }
    },

    sortEventPhotos() {
      let mainPhotoIndex = this.editedItem.photos.findIndex((f) => f.isMain);
      let mainPhoto = this.editedItem.photos.splice(mainPhotoIndex, 1)[0];
      this.editedItem.photos.unshift(mainPhoto);
    },

    async saveUploadedImages() {
      if (this.editedItem?.photoIds?.length) {
        let uploadedPhotoData = await this.uploadEventImages(this.editedItem);
        this.editedItem.photos = this.editedItem.photos.concat(uploadedPhotoData);
        this.sortEventPhotos();
        let selectedSpace = this.selectedFacilitySpace.id;
        let selectedFacility = this.selectedFacility.id;

        let requestUrl = `api/admin-facility-events/${selectedFacility}/space/${selectedSpace}/${this.editedItem.uuid}/user/${this.selectedUser.userId}/separate`;
       let data = this.editedItem.photos.flatMap(item => item.id)
        await ApiRequestService.patchRequest(requestUrl, {imageIds: data});
      }

      this.$refs.fileupload.reset();
    },

    async uploadEventImages(dataToCreate) {
      let photoData = [];

      for (let i = 0; i < dataToCreate?.photoIds?.length; i++) {
        const formData = new FormData();
        formData.append("file", dataToCreate?.photoIds[i], dataToCreate?.photoIds[i].name);

        let data = await ApiRequestService.postRequest('api/facility-event-photos/upload', formData);
        photoData.push(data);
      }

      return photoData;
    },

    deletePhoto(photoIndex) {
      event.stopPropagation();
      this.toDeletePhotoIndex = photoIndex;
      this.dialogDelete = true;
    },

    async deleteEventPhoto() {
      if (this.toDeletePhotoIndex < 0 || this.editedItem?.photos[this.toDeletePhotoIndex]?.isMain) {
        return;
      }

      this.editedItem.photos.splice(this.toDeletePhotoIndex, 1);
      this.toDeletePhotoIndex = -1;

      this.closeDelete();
    },

    openCarousel(photoIndex) {
      let carouselPhotos = this.getObjClone(this.editedItem.photos);
      carouselPhotos = (carouselPhotos.splice(photoIndex, carouselPhotos.length)).concat(carouselPhotos.splice(0, photoIndex));

      if (carouselPhotos?.length) {
        this.carouselPhotos = carouselPhotos;
        this.dialogCarousel = true;
      }
    },

    changeDate(date) {
      this.date = date;
      this.selectedDate = date;
      this.selectedTime = '';
      this.times = [];
      let selectedDate = this.availableDates.filter(item => {
        return `${item.date}` === `${date}`
      });

      if (!selectedDate.length) {
        return;
      }
      this.times = selectedDate[0].time;

      if (this.date === this.getFormattedDateString(this.editedItem.bookingDate)) {
        this.selectTime(`${DateUtil.getItalianTimeFromUTC(moment().format( 'YYYY-MM-DD' ) + ' ' + this.editedItem.startTime?.substr(0, 5))}-${DateUtil.getItalianTimeFromUTC(moment().format( 'YYYY-MM-DD' ) + ' ' + this.editedItem.endTime?.substr(0, 5))}`);
      }
    },

    getObjClone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    closeDelete() {
      this.dialogDelete = false;
      this.dialogDeletePhoto = false;
      this.submitted = false;
    },

    closeDialogCarousel() {
      this.dialogCarousel = false;
      this.carouselPhotos = {}
    },

    async save() {
      this.submitted = true;

      if (!this.isDataValid()) {
        return;
      }

      let selectedSpace = this.selectedFacilitySpace.id;
      let selectedFacility = this.selectedFacility.id;

      let requestUrl = `api/admin-facility-events/${selectedFacility}/space/${selectedSpace}/${this.editedItem.uuid}/user/${this.selectedUser.userId}`;
      let result = await ApiRequestService.patchRequest(requestUrl, this.getSaveModel());

      if (result.id) {
        location.reload();
      }
    },

    getValidators() {
      return [
        {method: 'isRequiredFieldValid', errorKey: 'nameError', value: this.editedItem.name},
        {method: 'isRequiredFieldValid', errorKey: 'dateError', value: this.selectedDate},
        {method: 'isRequiredFieldValid', errorKey: 'timeError', value: this.selectedTime},
        {method: 'isRequiredFieldValid', errorKey: 'userError', value: this.selectedUser},
        {
          method: 'isRequiredFieldValid',
          errorKey: 'organizationError',
          value: this.selectedOrganization
        },
        {
          method: 'areParticipantsValid',
          errorKey: 'participantsError',
          value: {
            selectedParticipants: [...this.selectedParticipants, ...this.selectedNewParticipants],
            maxNumberOfParticipants: this.editedItem.maxNumberOfParticipants
          }
        },

      ]
    },

    isDataValid() {
      if (!this.submitted) {
        return true;
      }

      this.validateUtil.setValidators(this.getValidators());
      return this.validateUtil.isDataValid();
    },


    getSaveModel() {
      let selectedTime = this.selectedTime.split('-');

      let data = {
        name: this.editedItem.name,
        description: this.editedItem.description,
        specialTimeRequest: this.editedItem.specialTimeRequest,
        specialRequest: this.editedItem.specialRequest,
        isPublic: this.editedItem.isPublic,
        booking: {
          bookingDate: new Date(this.selectedDate).toISOString(),
          startTime: momentTimezone.tz(this.selectedDate + ' ' + selectedTime[0]?.substr(0,5), TIMEZONE).valueOf(),
          endTime: momentTimezone.tz(this.selectedDate + ' ' + selectedTime[1]?.substr(0,5), TIMEZONE).valueOf()
        }
      };

      if (this.selectedServices.length) {
        data['serviceIds'] = this.selectedServices.flatMap(item => item.id);
      }

      if (this.editedItem.photos.length) {
        data['imageIds'] = this.editedItem.photos.flatMap(item => item.id)
      }

      let participants = this.getParticipants();

      if (participants) {
        data.booking['participants'] = participants;
      }

      return data
    },

    getParticipants() {
      let participants = [];

      this.selectedParticipants.forEach(item => {
        participants.push(item.email);
      });

      this.selectedNewParticipants.forEach(item => participants.push(item));
      return participants.length ? participants : null;
    },
  }
}

